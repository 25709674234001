import styled from '@emotion/styled';
import { borderRadius, colors, margin, mediaPoints } from '../styles/variables';

export const SupportTicketWrapper = styled.div`
  max-width: 468px;
  margin: auto;

  .page-head {
    margin-bottom: ${margin.base};
  }

  .contact-support-form {
    padding: 24px;
    border-radius: 1.2em;
    box-shadow: 0px 1px 4px 0px rgba(35, 36, 40, 0.12);
    background: ${colors.white};

    a {
      color: ${colors.brond};
      text-decoration: underline;
      &:hover {
        color: ${colors.duskDark};
      }
    }
  }

  .alert-icon {
    padding: 0.6rem 0.4rem;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .hint-serial-number {
    display: flex;
    margin-top: ${margin.base} / 2;
    margin-bottom: ${margin.base};
    color: ${colors.storm83};
    line-height: 22px;
    .info-icon {
      display: flex;
      align-items: center;
      overflow: visible;
      width: 1.6rem;
      height: 22px;
      margin-right: 5px;
    }
  }
  .category-alert {
    display: flex;
    margin-top: ${margin.base} / 2;
    margin-bottom: ${margin.base};
    color: #c00;
    line-height: 22px;
  }
  .text-box {
    position: relative;
    .word-counter {
      position: absolute;
      bottom: 0;
      right: 0;
      color: ${colors.storm83};
    }
    padding-bottom: 10px;

    .help-block {
      margin-bottom: -20px;
    }
    label {
      display: inherit;
    }
    ul {
      margin-bottom: 20px;
      display: inline-block;
      max-width: 97%;
    }
  }

  .security-reminder,
  .view-private-policy {
    color: ${colors.storm83};
  }

  .security-reminder {
    margin-bottom: 1.2rem;
  }

  .btn-row {
    margin-top: 2.4rem;
  }

  textarea {
    width: 100% !important;
    height: 100px !important;
  }

  .premium-support-booking-form {
    padding: 24px;
    border-radius: 1.2em;
    box-shadow: 0px 1px 4px 0px rgba(35, 36, 40, 0.12);
    background: ${colors.white};

    a {
      color: ${colors.brond};
      text-decoration: underline;
      &:hover {
        color: ${colors.duskDark};
      }
    }

    .timezone {
      display: flex;
      align-items: center;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  .description_failed_title {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .description_success_title {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  button.btn-link {
    font-weight: bold;
  }
`;

export const PremiumSupportNotificationWrapper = styled.div`
  .premium-contact-support-notification {
    display: flex;
    flex-direction: row;
    border-radius: ${borderRadius.alert};
    box-shadow: 0px 1px 4px 0px rgba(35, 36, 40, 0.12);
    background: ${colors.white};
    border: 1px solid ${colors.brond};
    margin: 0 auto 1rem auto;
    max-width: 570px;
    .premium-support-icon {
      margin: 1rem 0.3rem 1rem 1rem;
    }
    .premium-support-description {
      margin: 1rem 3rem 1rem 0.3rem;
    }
    a {
      color: ${colors.brond};
      text-decoration: underline;
      &:hover {
        color: ${colors.duskDark};
      }
    }
  }
`;

export const HeaderWrapper = styled.header`
  .support-title,
  .support-sub-title {
    margin-bottom: 30px;
  }
  .support-introduction {
    margin-top: -10px;
  }
  .contact-support-button {
    margin-top: 20px;
  }
`;

export const SupportRequestWrapper = styled.div`
  .support-request-tabs {
    margin-top: -10px;
  }
  .pagination {
    text-align: center;
  }
  .no-results-found {
    text-align: center;
    & > div {
      border-radius: 0;
    }
  }

  .table-data__container > .loading-content {
    background: ${colors.white};
  }

  .table-data__container > .forbidden-component,
  .table-data__container > .error-component {
    margin-top: -12px;
    > div:nth-child(2) {
      border-radius: 0px;
    }
  }

  .table-data__container {
    button {
      background: none;
      border: none;
      padding: 0;
      color: ${colors.brond};
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      > svg {
        margin-left: 10px;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

export const SupportRequestDetailsWrapper = styled.div`
  margin-bottom: 30px;
  .loading-content {
    background: ${colors.white};
  }
  .support-request-description-header .table-data--header {
    border-radius: 0;
  }
  .support-request-description {
    max-height: 150px;
    background: #fff;
    display: block;
    overflow: auto;
    & > div {
      white-space: normal;
    }
    .description-container {
      white-space: pre-wrap;
    }
    @media (max-width: ${mediaPoints.sm}px) {
      .description-container {
        padding-top: 10px;
        white-space: pre-wrap;
      }
    }
  }
`;

export const CommunicationHistoryWrapper = styled.div`
  @media (max-width: ${mediaPoints.sm}px) {
  }
  overflow: auto;
  flex: 1;
  position: relative;
  border-radius: 0.5rem;
  background: ${colors.white};

  .scroll-y {
    overflow-y: scroll;
  }

  .communication-card {
    color: ${colors.duskDarker};
    padding-bottom: 0;
    margin: 30px 40px 0 40px;
    max-height: 1200px;

    .conversation {
      border-bottom: 1px solid ${colors.storm17};
      margin-bottom: 20px;
      .avatar-name {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        > span {
          margin-left: 15px;
        }
      }

      h5 {
        margin-bottom: 20px;
      }

      .text-container {
        color: ${colors.duskDarker};
        line-height: 1.5em;
        max-height: calc(1.5em * 6);
        overflow: hidden;
        white-space: pre-wrap;
      }
      .text-container-unfolded {
        color: ${colors.duskDarker};
        height: 100%;
        white-space: pre-wrap;
      }

      button {
        background: none;
        border: none;
        padding: 0;
        color: ${colors.brond};
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        > svg {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const ReplyBoxWrapper = styled.div`
  padding: 8px 56px 30px 56px;
  background: ${colors.white};

  .security-reminder {
    color: ${colors.storm72};
  }
`;

export const SupportDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationWrapper = styled.div`
  margin-bottom: 11px;
`;
